import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Meta from '../components/meta';
import TitleBar from '../components/TitleBar';
import HeaderGraphic from '../components/HeaderGraphic';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <Meta title="Home" keywords={['gatsby', 'application', 'react']} />
           </MDXTag>;
  }

}
export const _frontmatter = {};
    